@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');
@import 'primeng/resources/themes/md-light-indigo/theme.css';

html,
body,
cf-storybook-shell-root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

html {
  font-family: 'Roboto Condensed', 'Courier New', Courier, monospace;
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
}
